var app = {

  init: function() {
    app.sliders();
    app.counter();

  }, // init END

  animation: function() {
    new WOW().init();
  }, // animation END

  lazyload: function() {
    var bLazy = new Blazy({
        breakpoints: [{
          width: 576, // max-width
          src: 'data-src-small'
        },
        {
          width: 990, // max-width
          src: 'data-src-medium'
        }],
        success: function(element){
          setTimeout(function(){
          // We want to remove the loader gif now.
          // First we find the parent container
          // then we remove the "loading" class which holds the loader image
          var parent = element.parentNode;
          parent.className = parent.className.replace(/\bloading\b/,'');
        }, 200);
      }
    });
  }, // lazyload END

  sliders: function() {

    var $slider = new Swiper('#testimonials', {
      slidesPerView: 3,
      initialSlide: 1,
      speed: 400,
      spaceBetween: 30,
      centeredSlides: true,
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
      },
      breakpoints: {
        768: {
          slidesPerView: 1,
          spaceBetween: 0
        },
        1200: {
          slidesPerView: 3,
          spaceBetween: 30
        }
      },
    });

  }, // slider END

  counter: function() {
    function getTimeRemaining(endtime) {
      var t = Date.parse(endtime) - Date.parse(new Date());
      var seconds = Math.floor((t / 1000) % 60);
      var minutes = Math.floor((t / 1000 / 60) % 60);
      var hours = Math.floor((t / (1000 * 60 * 60)) % 24);
      var days = Math.floor(t / (1000 * 60 * 60 * 24));
      return {
        'total': t,
        'days': days,
        'hours': hours,
        'minutes': minutes,
        'seconds': seconds
      };
    }

    function initializeClock(id, endtime) {
      var clock = document.getElementById(id);
      var daysSpan = clock.querySelector('.days');
      var hoursSpan = clock.querySelector('.hours');
      var minutesSpan = clock.querySelector('.minutes');
      var secondsSpan = clock.querySelector('.seconds');

      function updateClock() {
        var t = getTimeRemaining(endtime);

        daysSpan.innerHTML = t.days;
        hoursSpan.innerHTML = ('0' + t.hours).slice(-2);
        minutesSpan.innerHTML = ('0' + t.minutes).slice(-2);
        secondsSpan.innerHTML = ('0' + t.seconds).slice(-2);

        if (t.total <= 0) {
          clearInterval(timeinterval);
        }
      }

      updateClock();
      var timeinterval = setInterval(updateClock, 1000);
    }

    var deadline = new Date(Date.parse(new Date()) + 15 * 24 * 60 * 60 * 1000);
    initializeClock('clockdiv', deadline);
  }, // counter END

}

jQuery(document).ready(function($) {
  console.log( 'Документ и все ресурсы загружены' );
  app.init();

  $('#tabs a').on('click', function (e) {
    e.preventDefault()
    $(this).tab('show')
  });
});
